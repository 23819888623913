<div class="tabs-container">
  <ul
    class="nav-tabs at-flex at-align-center at-gap-md"
    (click)="preventSwitchTabs() && discard.emit()"
    [ngClass]="{
      pill: tabPill(),
      'at-border-bottom at-border-neutral-200 at-pb-2xl': tabsBorder(),
    }">
    @for (tab of tabs; track $index) {
      <li
        class="tab at-flex at-align-center at-py-sm at-px-md"
        [ngClass]="{
          active: tab.active() === true && !tabPill(),
          disable: tab.disable(),
          pill: tabPill(),
          pill_active: tab.active() === true && tabPill(),
        }"
        (click)="!tab.disable() ? selectTab(tab, $index) : null">
        @if (tab.tabDefaultIcon()) {
          <img
            [src]="tab.active() ? tab.tabActiveIcon() : tab.tabDefaultIcon()"
            class="tab-icon"
            [alt]="tab.tabTitle()" />
        }
        <span class="tab-title at-text-xs at-fw-medium">
          {{ tab.tabTitle() }}
        </span>

        @if (tab.showTabTag()) {
          <span
            class="tab-tag at-text-xs at-fw-medium at-bg-neutral-50 at-radius-xxl">
            {{ tab.tabTag() }}
          </span>
        }
      </li>
    }
  </ul>
  <ng-content></ng-content>
</div>
