import { CommonModule, DatePipe } from '@angular/common';
import { AfterViewInit, Component, inject, input, model } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InputComponent } from '../input/input.component';
import { DatePipePipe } from './date-pipe/date-pipe.pipe';

@Component({
  selector: 'lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, DatePipe, DatePipePipe, InputComponent],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class DatePickerComponent implements AfterViewInit {
  id = input.required<string>();
  label = input<string>();
  name = input.required<string>();
  placeholder = input.required<string>();
  value = input<Date>();
  disabled = input<boolean>(false);
  inputFormControlName = input.required<string>();
  private readonly parentContainer = inject(ControlContainer);
  isShowMonths = model<boolean>(false);
  isShowYears = model<boolean>(false);
  viewValue = model<Date | string>();
  required = input<boolean>(false);
  valueChangesSubscription = new Subscription();

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  currentDate: Date = new Date();
  currentMonth: number = this.currentDate.getMonth();
  currentYear: number = this.currentDate.getFullYear();
  showCalendar = false;
  selectedDate?: Date;
  months: { name: string; value: number }[] = [
    { name: 'January', value: 0 },
    { name: 'February', value: 1 },
    { name: 'March', value: 2 },
    { name: 'April', value: 3 },
    { name: 'May', value: 4 },
    { name: 'June', value: 5 },
    { name: 'July', value: 6 },
    { name: 'August', value: 7 },
    { name: 'September', value: 8 },
    { name: 'October', value: 9 },
    { name: 'November', value: 10 },
    { name: 'December', value: 11 },
  ];
  years: number[] = Array.from(
    { length: 50 },
    (_, i) => this.currentYear - 45 + i
  );

  get reversedYears(): number[] {
    return this.years.slice().reverse();
  }

  get monthDates(): Date[] {
    const dates: Date[] = [];
    const firstDay = new Date(this.currentYear, this.currentMonth, 1);
    const lastDay = new Date(this.currentYear, this.currentMonth + 1, 0);

    // Fill in the previous month's days
    const prevMonthLastDay = new Date(
      this.currentYear,
      this.currentMonth,
      0
    ).getDate();
    for (let i = firstDay.getDay(); i > 0; i--) {
      dates.push(
        new Date(
          this.currentYear,
          this.currentMonth - 1,
          prevMonthLastDay - i + 1
        )
      );
    }

    // Fill in the current month's days
    for (let i = 1; i <= lastDay.getDate(); i++) {
      dates.push(new Date(this.currentYear, this.currentMonth, i));
    }

    // Fill in the next month's days
    for (let i = 1; i <= 42 - dates.length; i++) {
      dates.push(new Date(this.currentYear, this.currentMonth + 1, i));
    }

    return dates;
  }

  ngAfterViewInit(): void {
    this.childControl.valueChanges.subscribe(value => {
      this.viewValue.set(value);
    });
  }

  openCalendar() {
    if (this.showCalendar) {
      this.showCalendar = false;
      return;
    }

    this.selectedDate = this.viewValue() as Date;
    this.showCalendar = true;
    this.childControl.markAsTouched();
  }

  selectDate(date: Date) {
    this.selectedDate = date;
    // this.isSelectedDate = this.isSelected(date);
  }

  selectMonth(month: { name: string; value: number }) {
    this.currentMonth = month.value;
    this.isShowMonths.set(false);
  }

  selectYear(year: number) {
    this.currentYear = year;
    this.isShowYears.set(false);
  }

  showMonths() {
    this.isShowMonths.set(true);
    this.isShowYears.set(false);
  }

  showYears() {
    this.isShowYears.set(true);
    this.isShowMonths.set(false);
  }

  prevMonth() {
    this.currentMonth--;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.currentYear--;
    }
  }

  nextMonth() {
    this.currentMonth++;
    if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.currentYear++;
    }
  }

  close() {
    this.showCalendar = false;
    this.selectedDate = undefined;
    this.isShowMonths.set(false);
    this.isShowYears.set(false);
  }

  apply() {
    const control = this.parentFormGroup.get(this.inputFormControlName());
    const controlValueDate = new Date(control?.value);
    const selectDateValue = new Date(this.selectedDate as Date);

    controlValueDate.setHours(0, 0, 0, 0);
    selectDateValue.setHours(0, 0, 0, 0);

    if (controlValueDate.getTime() === selectDateValue.getTime()) {
      this.close();
      return;
    }
    if (control && this.selectedDate) {
      control.setValue(
        this.selectedDate.getDate() +
          '-' +
          (+this.selectedDate.getMonth() + 1) +
          '-' +
          this.selectedDate.getFullYear()
      );
      this.viewValue.set(this.selectedDate);
      this.showCalendar = false;
      this.childControl.markAsDirty();
    }
    this.selectedDate = undefined;
  }
}
