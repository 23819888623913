import {
  AfterViewInit,
  Component,
  inject,
  input,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { TruncatePipe } from '../../pipes/truncate/truncate.pipe';

@Component({
  selector: 'lib-validation-message',
  standalone: true,
  imports: [CommonModule, TruncatePipe],
  templateUrl: './validation-message.component.html',
  styleUrl: './validation-message.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class ValidationMessageComponent implements AfterViewInit, OnDestroy {
  control = input.required<string>();
  label = input<string>('');
  customErrorMessages = input<Record<string, string>>({});
  errorMessages!: Record<string, string>;
  controlValueSubscriptions = new Subscription();
  formArrayParentName = input<string>('');
  formGroupChildName = input<number>(0);
  messageClass = input<string>('');
  truncateValue = input<number>(0);

  private parentContainer = inject(ControlContainer);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl(): FormControl {
    return this.parentFormGroup.controls[this.control()] as FormControl;
  }

  ngAfterViewInit(): void {
    // for input validation detection
    this.controlValueSubscriptions = this.childControl.statusChanges.subscribe(
      () => {
        if (this.control() && this.childControl.errors) {
          this.updateErrorMessages(this.childControl.errors);
        }
      }
    );

    // for select validation detection
    if (this.control() && this.childControl.errors) {
      this.updateErrorMessages(this.childControl.errors);
    }
  }

  updateErrorMessages(errorsObject: ValidationErrors) {
    this.errorMessages = {
      required: (this.label() || 'This field') + ' is required',
      email: 'Invalid Email format',
      minlength:
        'The minimum characters you should enter is ' +
        errorsObject?.['minlength']?.['requiredLength'] +
        ', but you entered ' +
        errorsObject?.['minlength']?.['actualLength'],
      maxlength:
        'The maximum characters you should enter is ' +
        errorsObject?.['maxlength']?.['requiredLength'] +
        ', but you entered ' +
        errorsObject?.['maxlength']?.['actualLength'],
      pattern: 'Invalid input. Please follow the required format',
      customError: errorsObject?.['errorMessage'],
      apiError: errorsObject?.['apiErrorMessage'],
      ...this.customErrorMessages(),
    };
  }

  ngOnDestroy(): void {
    this.controlValueSubscriptions.unsubscribe();
  }
}
