@if (label()) {
  <div class="at-mb-x-sm">
    <span
      class="at-paragraph-small at-fw-700 at-text-primary-500 at-fw-medium"
      [ngClass]="{
        'at-text-secondary4 opacity-30':
          disabled() || documentList().length === uploadedFileLimit(),
        'at-text-accent1': !(
          disabled() || documentList().length === uploadedFileLimit()
        ),
        'label-required': required(),
      }">
      {{ label() }}
    </span>
  </div>
}
<label
  [for]="id()"
  [formGroup]="formGroup()"
  class="position-relative"
  [class]="inputClass()"
  [ngClass]="{
    active: isDraggedIn,
    error: errorMessage(),
    disabled: disabled() || documentList().length === uploadedFileLimit(),
  }">
  <input
    class="document-input"
    [formControlName]="inputFormControlName()"
    [style.cursor]="
      disabled() || documentList().length === uploadedFileLimit()
        ? 'not-allowed'
        : 'pointer'
    "
    [id]="
      disabled() || documentList().length === uploadedFileLimit() ? '' : id()
    "
    [name]="name()"
    [type]="
      disabled() || documentList().length === uploadedFileLimit()
        ? 'text'
        : 'file'
    "
    [accept]="accept()"
    (click)="checkOnValidators()"
    (change)="onDocumentChange($event)"
    (dragenter)="isDraggedIn = true"
    (drop)="isDraggedIn = false; onDocumentChange($event)"
    [readonly]="
      readonly || disabled() || documentList().length === uploadedFileLimit()
    "
    [value]="value()"
    [dir]="direction()" />

  <div class="document-view at-border at-border-neutral-200 at-p-sm">
    <div class="at-mb-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M9 17V11L7 13"
          stroke="#4B4EFC"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M9 11L11 13"
          stroke="#4B4EFC"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
          stroke="#4B4EFC"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
        <path
          d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
          stroke="#4B4EFC"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>

    <div class="at-mb-sm">
      <p class="at-text-neutral-900 at-mb-x-sm">
        <span class="at-text-primary-500">Upload a file</span> or drag and drop
      </p>
      <div class="at-text-neutral-500">{{ fileTypeDescription() }}</div>
    </div>
    <!-- @if (documentList()) {
    <div class="hint-message">{{ documentList() }}</div>
    } -->
  </div>
</label>

@if (errorMessage()) {
  <div
    class="error-message at-text-destructive-500 at-text-sm at-fw-normal at-mt-sm"
    [ngClass]="{
      disabled: disabled(),
    }">
    {{ errorMessage() }}
  </div>
}
@if (documentList() && documentList().length) {
  <div
    class="document-file-list at-mt-lg"
    [ngClass]="{ 'opacity-30': disabled() }">
    @for (file of documentList(); track file?.name; let i = $index) {
      <div class="document-file-item">
        <div class="at-flex at-align-center">
          <div class="document-icon-container at-bg-neutral-50 at-me-md">
            <span class="at-me-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M14.6666 6.66666V10C14.6666 13.3333 13.3333 14.6667 9.99998 14.6667H5.99998C2.66665 14.6667 1.33331 13.3333 1.33331 10V6C1.33331 2.66666 2.66665 1.33333 5.99998 1.33333H9.33331"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M14.6666 6.66666H12C9.99998 6.66666 9.33331 5.99999 9.33331 3.99999V1.33333L14.6666 6.66666Z"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M4.66669 8.66667H8.66669"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M4.66669 11.3333H7.33335"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
          </div>

          <div
            class="at-d-flex at-align-items-center"
            style="overflow-wrap: anywhere">
            <div class="at-w-100 at-text-start">
              <div class="">
                {{ file?.name }}
              </div>

              <!-- <div class="at-mt-sm at-text-sm at-text-neutral-500">
            {{ file.file.name }}
          </div> -->
            </div>
          </div>
        </div>

        <div>
          @if (isEditMode() && file?.result) {
            <button
              type="button"
              class="at-me-md"
              (click)="downloadFile(file?.result)">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.31982 11.6797L11.8798 14.2397L14.4398 11.6797"
                  stroke="#D2D4CF"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M11.8799 4V14.17"
                  stroke="#D2D4CF"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M20 12.1797C20 16.5997 17 20.1797 12 20.1797C7 20.1797 4 16.5997 4 12.1797"
                  stroke="#D2D4CF"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
          }
          @if (!disabled()) {
            <button
              class="trash-icon-container at-bg-distructive-50"
              (click)="removeFile(i)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M17.5 4.98333C14.725 4.70833 11.9333 4.56667 9.15 4.56667C7.5 4.56667 5.85 4.65 4.2 4.81667L2.5 4.98333"
                  stroke="#EF4444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M7.08331 4.14167L7.26665 3.05C7.39998 2.25833 7.49998 1.66667 8.90831 1.66667H11.0916C12.5 1.66667 12.6083 2.29167 12.7333 3.05833L12.9166 4.14167"
                  stroke="#EF4444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M15.7084 7.61667L15.1667 16.0083C15.075 17.3167 15 18.3333 12.675 18.3333H7.32502C5.00002 18.3333 4.92502 17.3167 4.83335 16.0083L4.29169 7.61667"
                  stroke="#EF4444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M8.60834 13.75H11.3833"
                  stroke="#EF4444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M7.91669 10.4167H12.0834"
                  stroke="#EF4444"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
          }
          @if (disabled()) {
            <button
              class="trash-icon-container at-bg-distructive-50"
              (click)="viewFile(file)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none">
                <path
                  d="M13.4842 10.4999C13.4842 12.1499 12.1509 13.4833 10.5009 13.4833C8.85091 13.4833 7.51758 12.1499 7.51758 10.4999C7.51758 8.84993 8.85091 7.5166 10.5009 7.5166C12.1509 7.5166 13.4842 8.84993 13.4842 10.4999Z"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M10.4999 17.3918C13.4415 17.3918 16.1832 15.6584 18.0915 12.6584C18.8415 11.4834 18.8415 9.50843 18.0915 8.33343C16.1832 5.33343 13.4415 3.6001 10.4999 3.6001C7.5582 3.6001 4.81654 5.33343 2.9082 8.33343C2.1582 9.50843 2.1582 11.4834 2.9082 12.6584C4.81654 15.6584 7.5582 17.3918 10.4999 17.3918Z"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
          }
        </div>
      </div>
    }
  </div>
}
