import {
  Directive,
  ElementRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef,
  input,
  inject,
  ChangeDetectorRef,
  output,
  TemplateRef,
  Renderer2,
  AfterViewInit,
  model,
  effect,
} from '@angular/core';
import { TooltipComponent, TooltipPlacement } from './tooltip.component';

@Directive({
  selector: '[libTooltip]',
  standalone: true,
})
export class TooltipDirective implements AfterViewInit {
  libTooltip = input<string>('');
  description = input<string>();

  hasCloseIcon = input<boolean>(true);
  hasActionButton = input<boolean>(false);
  actionButtonLabel = input<string>();
  toolTipClass = input<string>('');
  buttonAction = output();

  placement = input<TooltipPlacement>('right');
  toolTipTemplate = input<TemplateRef<unknown> | null>(null);

  toolTipAction = input<'click' | 'mouseenter'>('mouseenter');
  isOpened = model<boolean>(false);

  private el = inject(ElementRef);
  private vcr = inject(ViewContainerRef);
  private resolver = inject(ComponentFactoryResolver);
  private cdr = inject(ChangeDetectorRef);
  private renderer = inject(Renderer2);

  private tooltipRef: ComponentRef<TooltipComponent> | undefined;

  private eventListener: (() => void) | undefined;
  private eventListener_2: (() => void) | undefined;

  constructor() {
    effect(() => {
      this.isOpened() === false ? this.onCloseToolTip() : '';
    });
  }

  ngAfterViewInit(): void {
    this.action(this.toolTipAction());
    this.toolTipAction() === 'mouseenter' ? this.mouseLeave() : '';
  }

  action(eventName: string) {
    if (this.eventListener) {
      this.eventListener();
    }
    this.eventListener = this.renderer.listen(
      this.el.nativeElement,
      eventName,
      () => {
        if (!this.tooltipRef) {
          const factory =
            this.resolver.resolveComponentFactory(TooltipComponent);
          this.tooltipRef = this.vcr.createComponent(factory);
          this.setProperties();
          this.setPosition();
          this.cdr.detectChanges();
        }
        if (this.toolTipAction() === 'click') {
          this.isOpened.set(true);
        }
      }
    );
  }

  mouseLeave() {
    if (this.eventListener_2) {
      this.eventListener_2();
    }

    this.eventListener_2 = this.renderer.listen(
      this.el.nativeElement,
      'mouseleave',
      event => {
        const tooltipElement = this.tooltipRef?.location.nativeElement;
        if (
          tooltipElement &&
          tooltipElement.contains(event.relatedTarget as Node)
        ) {
          return;
        }
        this.destroyTooltip();
      }
    );
  }

  private setProperties() {
    if (this.tooltipRef) {
      this.tooltipRef.instance.title = this.libTooltip;
      this.tooltipRef.instance.description = this.description;
      this.tooltipRef.instance.hasCloseIcon = this.hasCloseIcon;
      this.tooltipRef.instance.hasActionButton = this.hasActionButton;
      this.tooltipRef.instance.actionButtonLabel = this.actionButtonLabel;
      this.tooltipRef.instance.toolTipTemplate = this.toolTipTemplate;
      this.tooltipRef.instance.toolTipClass = this.toolTipClass;
      this.tooltipRef.instance.placement = this.placement;
      this.tooltipRef.instance.isOpened = this.isOpened;
      this.tooltipRef.instance.toolTipAction = this.toolTipAction;
      this.tooltipRef.instance.action.subscribe(() =>
        this.onTooltipButtonClick()
      );
      this.tooltipRef.instance.onMouseLeaveToolTip.subscribe(() =>
        this.toolTipAction() === 'mouseenter' ? this.onCloseToolTip() : ''
      );
      this.tooltipRef.instance.close.subscribe(() => this.onCloseToolTip());
    }
  }

  private setPosition() {
    this.cdr.detectChanges();

    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipWidth = this.tooltipRef?.instance.templateWidth;
    const tooltipHeight = this.tooltipRef?.instance.templateHeight;

    let top, left;

    if (tooltipWidth && tooltipHeight) {
      switch (this.placement()) {
        case 'top':
          top = hostPos.top - tooltipHeight - 20;
          left = hostPos.left + (hostPos.width - tooltipWidth) / 2;
          break;
        case 'bottom':
          top = hostPos.bottom + 20;
          left = hostPos.left + (hostPos.width - tooltipWidth) / 2;
          break;
        case 'left':
          top = hostPos.top - tooltipHeight / 2;
          left = hostPos.left - tooltipWidth - 10;
          break;
        case 'right':
          top = hostPos.top - tooltipHeight / 2;
          left = hostPos.right + 10;
          break;
      }
    }

    if (this.tooltipRef) {
      this.tooltipRef.instance.top.set(top + 'px');
      this.tooltipRef.instance.left.set(left + 'px');
    }
  }

  private destroyTooltip() {
    if (this.tooltipRef) {
      if (this.toolTipAction() === 'click') {
        this.tooltipRef.instance.isOpened.set(false);
      } else {
        this.tooltipRef.destroy();
        this.tooltipRef = undefined;
      }
    }
  }

  private onCloseToolTip() {
    this.destroyTooltip();
  }

  private onTooltipButtonClick() {
    this.buttonAction.emit();
    this.destroyTooltip();
  }
}
