import {
  AfterViewInit,
  Component,
  OnInit,
  inject,
  input,
  model,
} from '@angular/core';
import { InputComponent } from '../input/input.component';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NumberDirective } from '../../directives/number-directive/number.directive';

@Component({
  selector: 'lib-money-input',
  standalone: true,
  imports: [InputComponent, ReactiveFormsModule, NumberDirective],
  templateUrl: './money-input.component.html',
  styleUrl: './money-input.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class MoneyInputComponent implements OnInit, AfterViewInit {
  id = input.required<string>();
  label = input<string>();
  name = input.required<string>();
  placeholder = input.required<string>();
  inputFormControlName = input.required<string>();
  ViewValue = model<string>();
  required = input<boolean>(false);
  disabled = input<boolean>(false);
  formGroup = new FormGroup({
    money: new FormControl(),
  });

  private readonly parentContainer = inject(ControlContainer);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.required()) {
      this.formGroup.controls['money'].setValidators([Validators.required]);
    }

    if (this.disabled()) {
      this.formGroup.controls['money'].disable();
    }
  }

  ngAfterViewInit(): void {
    if (this.childControl.value) {
      const returnedValue = this.formatNumberWithCommas(
        this.childControl.value.toString()
      );
      this.ViewValue.set(returnedValue);
    }
  }

  onKeyPress() {
    const value = this.formGroup.controls['money'].value;
    this.childControl.markAsTouched();
    if (value) {
      this.ViewValue.set(this.formatNumberWithCommas(value));
      this.childControl?.setValue(Number(value.replace(/,/g, '')));
      this.childControl.markAsDirty();
    } else {
      this.childControl?.setValue(null);
    }
  }

  formatNumberWithCommas(value: string): string {
    value = value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
}
