<div [formGroup]="formGroup">
  <lib-input
    libNumber
    [id]="id()"
    [inputFormControlName]="'money'"
    [type]="'text'"
    [required]="required()"
    [name]="name()"
    [placeholder]="placeholder()"
    [label]="label()"
    (input)="onKeyPress()"
    [value]="ViewValue()"
    [disabled]="disabled()"></lib-input>
</div>
