@if (label()) {
  <label
    class="at-mb-x-sm at-block at-text-neutral-900 at-fw-medium at-text-sm"
    [ngClass]="{
      'at-cursor-not-allowed at-text-neutral-300': disabled(),
      ' at-cursor-pointer at-text-neutral-900': !disabled(),
      'label-required': required(),
    }"
    (click)="!disabled() && toggleDropdown()"
    [for]="id()">
    {{ label() }}
  </label>
}

<!-- Select Container -->
<div
  class="at-position-relative"
  libClickOutSide
  (clickOutside)="clickedOutside()">
  <label
    (click)="!disabled() && toggleDropdown()"
    class="select at-flex at-align-center at-w-100 at-border at-border-neutral-300 at-radius-sm at-drop-shadow-xs at-text-sm at-ps-md at-pe-md"
    [ngClass]="{
      'at-cursor-not-allowed at-text-neutral-300': disabled(),
      'at-cursor-pointer': !disabled(),
      focus: isDropdownOpen,
      'at-text-neutral-400':
        !disabled() &&
        !(childControl.touched && childControl.dirty && childControl.errors),
      'has-errors at-text-destructive-900':
        childControl.touched && childControl.dirty && childControl.errors,
    }"
    [class]="inputClass()">
    @if (selectedOption && selectedOption['flag']) {
      <label
        class="at-d-flex at-pointer at-position-relative at-me-2 flag at-line-height"
        [style.cursor]="disabled() ? 'not-allowed' : ''"
        (click)="!disabled() && toggleDropdown()">
        <img
          [src]="selectedOption['flag']"
          [alt]="label()"
          class="at-me-md"
          width="16"
          height="16" />
      </label>
    }

    <input
      type="hidden"
      [id]="id()"
      [readOnly]="readonly()"
      [formControlName]="inputFormControlName()"
      [required]="required()"
      [value]="value() ?? null"
      [placeholder]="placeholder()"
      class="hidden-input" />

    <span
      [ngClass]="{
        'at-text-neutral-300': !selectedOption[selectedKeyName()],
        'at-text-neutral-900': selectedOption[selectedKeyName()] && !disabled(),
        'at-cursor-not-allowed  at-text-neutral-300': disabled(),
      }">
      {{ selectedOption[selectedKeyName()] ?? placeholder() | truncate: 32 }}
    </span>

    <div
      class="icon at-inset-inline-end-md at-cursor-pointer"
      [attr.disabled]="disabled()">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
  </label>

  @if (isDropdownOpen) {
    <div
      class="dropdown-list at-inset-inline-start-0 at-position-absolute at-text-start">
      @if (hasSearch()) {
        <input
          class="dropdown-search at-position-sticky at-inset-block-start-0 at-py-md at-px-2xl at-w-100"
          type="search"
          [placeholder]="searchPlaceholder()"
          (input)="onDropdownSearch($event)" />
      }
      @if (!showNoSearchResult) {
        @for (
          option of filteredOptions.length ? filteredOptions : options();
          track option
        ) {
          <div
            class="dropdown-item at-cursor-pointer at-flex at-align-center at-py-md at-px-2xl at-border-inline-start-3 at-border-white"
            (click)="selectOption(option, true); isDropdownOpen = false">
            @if (option) {
              @if (option['flag']) {
                <img
                  [src]="option['flag']"
                  [alt]="label()"
                  class="at-me-md"
                  width="16"
                  height="16" />
              }
              @if (
                option['calling_code'] && selectedKeyName() === 'calling_code'
              ) {
                {{ option['calling_code'] }}
              }
              @if (option[selectedKeyName()]) {
                {{ option[selectedKeyName()] }}
              }
            }
          </div>
        }
      } @else {
        <div class="at-py-md at-px-2xl">
          {{ noSearchResultLabel() }}
        </div>
      }
    </div>
  }
</div>
